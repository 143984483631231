import React from 'react';
import PropTypes from 'prop-types';
import detect from 'browser-detect';

import Typography from '../Typography';
import Icon from '../Icon';

const Link = ({ href, children, showIcon = false }) => {
  return (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
    >
      <Typography
        variant="subtitle"
        component="p"
        color="primaryActive"
        className="flex items-center"
      >
        {children}
        {!!showIcon && (
          <Icon
            name="external-link"
            className="ml-2 w-5 text-white"
          />
        )}
      </Typography>
    </a>
  );``
};

const Row = ({ title, value, link }) => {
  return (
    <div className="mb-4 flex">
      <Typography
        variant="subtitle"
        component="p"
        className="w-48 text-white"
      >
        {title}
      </Typography>

      {link ? (
        <Link href={link}>{value}</Link>
      ) : (
        <Typography
          variant="subtitle"
          component="p"
          className="w-48 text-white"
        >
          {value}
        </Typography>
      )}
    </div>
  );
};

const AboutModal = ({ buildNumber, versionNumber, commitHash }) => {
  const { os, version, name } = detect();
  const browser = `${name[0].toUpperCase()}${name.substr(1)} ${version}`;

  const renderRowTitle = title => (
    <div className="mb-3 border-b-2 border-black pb-3">
      <Typography
        variant="inherit"
        color="primaryLight"
        className="text-[16px] font-semibold !leading-[1.2]"
      >
        {title}
      </Typography>
    </div>
  );
  return (
    <div>
      {renderRowTitle('Important Links')}
      <div className="mb-8 flex">
        <Link
          href="https://community.ohif.org/"
          showIcon={true}
        >
          Visit the forum
        </Link>
        <span className="ml-4">
          <Link
            href="https://github.com/OHIF/Viewers/issues/new/choose"
            showIcon={true}
          >
            Report an issue
          </Link>
        </span>
        <span className="ml-4">
          <Link
            href="https://ohif.org/"
            showIcon={true}
          >
            More details
          </Link>
        </span>
      </div>

      {renderRowTitle('Version Information')}
      <div className="flex flex-col">
        <Row
          title="Repository URL"
          value="https://github.com/OHIF/Viewers/"
          link="https://github.com/OHIF/Viewers/"
        />
        <Row
          title="Data Citation"
          value="https://github.com/OHIF/Viewers/blob/master/DATACITATION.md"
          link="https://github.com/OHIF/Viewers/blob/master/DATACITATION.md"
        />
        {/* <Row
          title="Last Master Commits"
          value="https://github.com/OHIF/Viewers/"
          link="https://github.com/OHIF/Viewers/"
        /> */}
        <Row
          title="Version number"
          value={versionNumber}
        />
        {buildNumber && (
          <Row
            title="Build number"
            value={buildNumber}
          />
        )}
        {commitHash && (
          <Row
            title="Commit Hash"
            value={commitHash}
          />
        )}
        <Row
          title="Browser"
          value={browser}
        />
        <Row
          title="OS"
          value={os}
        />
        {renderRowTitle('Disclaimer')}
        <p>
          The OHIF Medical Imaging Viewer is a zero-footprint medical image viewer provided by the Open Health Imaging
          Foundation (OHIF), integrated in Vida24 to enable image viewing.
          <br />OHIF is a configurable and extensible progressive web application with out-of-the-box support for image
          archives which support DICOMweb.
          <br />
          THE SOFTWARE BOTH PRIOR AND POST ITS INTERGRATION IN VIDA24 IS PROVIDED “AS IS” WITHOUT ANY WARRANTIES OF ANY
          KIND. TO THE MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW, VIDAVO AND ITS THIRD PARTY PROVIDERS AND LICENSORS
          EXPRESSLY DISCLAIM ANY IMPLIED WARRANTIES RELATING TO THE SOFTWARE OR ANY MATERIALS PROVIDED HEREUNDER,
          INCLUDING, WITHOUT LIMITATION, ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
          NON-INFRINGEMENT OF INTELLECTUAL PROPERTY RIGHTS, OR WARRANTIES REGARDING RESULTS OBTAINABLE THROUGH USE OF
          THE SOFTWARE OR MATERIALS, OR ANY WARRANTY THAT USE OF THE SOFTWARE SHALL BE UNINTERRUPTED OR ERROR-FREE.
          Vidavo IN NO EVENT WILL BE LIABLE FOR ANY LOST PROFITS OR BUSINESS OPPORTUNITIES, LOSS OF USE, BUSINESS
          INTERRUPTION, LOSS OF DATA, OR ANY OTHER INDIRECT, SPECIAL, INCIDENTAL, OR CONSEQUENTIAL DAMAGES ARISING OUT
          OF THE SOFTWARE OR THE USE OF THE SOFTWARE, UNDER ANY THEORY OF LIABILITY, WHETHER BASED IN CONTRACT, TORT,
          NEGLIGENCE, PRODUCT LIABILITY, OR OTHERWISE. BECAUSE SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OR
          LIMITATION OF LIABILITY FOR CONSEQUENTIAL OR INCIDENTAL DAMAGES, THE PRECEDING LIMITATION MAY NOT APPLY TO THE
          MAXIMUM EXTENT PERMITTED BY APPLICABLE LAW.
        </p>
      </div>
    </div>
  );
};

AboutModal.propTypes = {
  buildNumber: PropTypes.string,
  versionNumber: PropTypes.string,
};

export default AboutModal;
