/**
 * Entry point for development and production PWA builds.
 */
import 'regenerator-runtime/runtime';
import { createRoot } from 'react-dom/client';
import App from './App';
import React from 'react';
import { history } from './utils/history';

/**
 * EXTENSIONS AND MODES
 * =================
 * pluginImports.js is dynamically generated from extension and mode
 * configuration at build time.
 *
 * pluginImports.js imports all of the modes and extensions and adds them
 * to the window for processing.
 */
import { extensions as defaultExtensions, modes as defaultModes } from './pluginImports';
import loadDynamicConfig from './loadDynamicConfig';

const urlParams = new URLSearchParams(window.location.search);
const token = urlParams.get('token');
//Checks for token parameter
if (!token) {
  console.error('Token not found!');
  Swal.fire({
    title: 'Error!',
    text: 'User authentication has expired. For security reasons please login again.',
    icon: 'error',
    confirmButtonText: 'Close',
    confirmButtonColor: '#222',
  });
  throw new Error('Token not found!');
}

//Delete token from url
urlParams.delete('token');

// Update the URL without a page reload
const newURL = window.location.pathname + '?' + urlParams.toString();
window.history.replaceState({}, document.title, newURL);

const studyUId = urlParams.get('StudyInstanceUIDs');

// Send a request to Laravel to check permissions

const formdata = new FormData();
formdata.append('study_instance_uid', studyUId);
formdata.append('vida_ohif_token', token);

const xhr = new XMLHttpRequest();

xhr.onreadystatechange = function () {
  if (xhr.readyState === 4) {
    // move the condition here
    if (xhr.status == 200) {
      console.log(xhr.response);

      let response = JSON.parse(xhr.response);
      console.log(response);
      let logo_url = response.logo_url;
      window.config.whiteLabeling = {
        /* Used to replace the default Logo */
        createLogoComponentFn: function (React) {
          return React.createElement(
            'img',
            {
              src: logo_url,
              className: 'header-brand',
              style: {
                display: 'block',
                width: '60px',
                height: '60px',
              },
            },
            null
          );
        },
      };

      loadDynamicConfig(window.config).then(config_json => {
        // Reset Dynamic config if defined
        if (config_json !== null) {
          window.config = config_json;
        }

        /**
         * Combine our appConfiguration with installed extensions and modes.
         * In the future appConfiguration may contain modes added at runtime.
         *  */
        const appProps = {
          config: window ? window.config : {},
          defaultExtensions,
          defaultModes,
        };

        const container = document.getElementById('root');

        const root = createRoot(container);
        root.render(React.createElement(App, appProps));
      });
    } else {
      Swal.fire({
        title: 'Error!',
        text: 'User authentication has expired. For security reasons please login again.',
        icon: 'error',
        confirmButtonText: 'Close',
        confirmButtonColor: '#222',
      });
      console.error('Unauthorized');
      throw new Error('Unauthorized');
    }
    console.log(xhr.status);
  }
};

xhr.open('POST', window.config.authorizeEndpoint);

xhr.send(formdata);

export { history };
